import { createRouter, createWebHashHistory } from 'vue-router'


const router = createRouter({
  // 3.1 通过 history 属性，指定路由的工作模式
  history: createWebHashHistory(),
  // 3.2 通过 routes 数组，指定路由规则
  // path 是 hash 地址，component 是要展示的组件
  routes: [
    { path: '/', component: ()=>import('./Main.vue'), meta: { title: 'Home' },
      children: [
        {
          path: '', name: 'Home', meta: { title: 'Home' }, component: ()=>import('./view/Home.vue'),
        },
        {
          path: 'ordinals', name: 'ordinals', meta: { title: 'ordinals' }, component: ()=>import('./view/Ordinals.vue'),
        },
        {
          path: 'bitcoinPets', name: 'bitcoinPets', meta: { title: 'bitcoinPets' }, component: ()=>import('./view/BitcoinPets.vue'),
        },
        {
          path: 'roadmap', name: 'roadmap', meta: { title: 'roadmap' }, component: ()=>import('./view/Roadmap.vue'),
        },
        {
          path: 'twitter', name: 'twitter', meta: { title: 'twitter' }, component: ()=>import('./view/Twitter.vue'),
        },
        {
          path: 'discord', name: 'discord', meta: { title: 'discord' }, component: ()=>import('./view/Discord.vue'),
        },
      ]
    },
  ],
})


export default router