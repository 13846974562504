<template>
  <div class="view" :style="{transform: `scale(${scale})`,transformOrigin: '50% 0'}">
    <router-view></router-view>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'

export default {
  name: 'App',
  setup() {
    const scale = ref(1)
    const timer = ref(false);
    const isSys = () => {
      let userAgentInfo = navigator.userAgent;
      let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
      let getArr = Agents.filter(i => userAgentInfo.includes(i));
      return getArr.length ? false : true;
    }
    const size = ()=>{
      if(isSys()) {
        const app = document.getElementById('app')
        const newScale = document.body.clientWidth / 1920
        if(newScale >= 1) {
          scale.value = 1
        } else {
          document.body.classList.add('body')
          app.classList.add('body')
          scale.value = newScale
          clearInterval(timer.value)
          timer.value = setTimeout(() => {
            const view = document.getElementsByClassName('view')?.[0]
            app.style.height = `${view.clientHeight * newScale}px`
          }, 100);
        }
      }
    }
    onMounted(size)
    
    //监听并更新视口宽度
    window.onresize = () => {
      size()
    }
    return {
      scale
    }
  }
}
</script>

<style>
#app {
  position: relative;
  width: 100%;
}
.view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 1911px;
}
</style>
